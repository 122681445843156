import React from 'react';

function InputBox(props) {
    return (
        <input type="text" className="physio_textbox" value={props.value} placeholder={props.placeholder} onChange={(e) => { props.handleOnChangeValue(props.keyName, e.target.value) }} />
    )
}

const styles = {
}

export default InputBox;