import React from 'react';

function DropDown(props) {
    return (
        <div>
            <div class="dropdown">
                <button type="button" id={`dropdownMenuButton${props.keyName}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: 'transparent', width: '100%', height: '3rem', border: '2px solid white', color: 'white', outline: 0, fontWeight: 'bold' }}>
                    {props.value && props.value != '' ? props.value : props.defaultValue}
                    <p class="dropdown-toggle d-inline" style={{ position: 'absolute', right: '20px' }}></p>
                </button>
                <div class="dropdown-menu" aria-labelledby={`dropdownMenuButton${props.keyName}`} style={{ width: '100%' }}>
                    {props.options && props.options.length > 0 ? props.options.map(item => (
                        <li class="dropdown-item" onClick={() => { props.handleOnSelectDropdown(props.keyName, item) }} style={{
                            fontWeight:'bold'
                        }}>{item}</li>
                    )) : null}
                </div>
            </div>
        </div>
    )
}

const styles = {
}

export default DropDown;