import React from 'react';

function Header(props) {
    return (
        <div>
            <div style={styles.headerBackground}>
                <div style={styles.headerBackgroundOverlay}>
                    <div className="container d-flex flex-column justify-content-center align-items-center h-100">
                        <h1 className="header_title" data-aos="fade-up" data-aos-duration="600">MYO TRAINING</h1>
                        <p className="header_content mt-4" data-aos="fade-up" data-aos-duration="600">Tailor-made programs to suit your needs and meet your goals.</p>
                        {/* <a href="/physio#physio-form" style={{ color: 'black', textDecoration: 'none' }} className="header_btn mt-3" data-aos="fade-up" data-aos-duration="600"><p>TRAIN WITH US</p></a> */}
                        <div style={{ position: 'absolute', bottom: 50, fontWeight: 'bold', fontSize: '18px' }}>
                            <a href="#training_section_2" style={{ color: 'white', textDecoration: 'none' }}>
                                <p style={{ color: '#fff' }}>EXPLORE OUR PACKAGE</p>
                            </a>
                        </div>
                        <div style={styles.headerBottomImage}>
                            <a href="#training_section_2" style={{ color: 'white', textDecoration: 'none' }}>
                                <img src="/images/down_arrow.svg" style={{ height: '70%', width: '80%', marginLeft: '13%' }} />
                            </a>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-baseline">
                    </div>
                </div>
            </div>
        </div >
    )
}

const styles = {
    headerBackground: { backgroundImage: "url(/images/training_hero.jpg)", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh' },
    headerBackgroundOverlay: { backgroundColor: ' rgba(0,0,0,0)', zIndex: 2, height: '100vh' },
    headerBottomContent: { position: 'absolute', bottom: 50 },
    headerBottomImage: { position: 'absolute', bottom: 30 }
}

export default Header;