import React, { Component } from 'react';
import Features from '../Components/Common/Features';
import Navbar from '../Components/Common/Navbar';
import BookAConsult from '../Components/Home/BookAConsult';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Home/Header';
import Services from '../Components/Common/Services';
import Team from '../Components/Common/Team';
import './HomeStyles.css'
import Testimonials from '../Components/Home/Testimonials';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.isReload) {
            window.location.reload();
            window.history.replaceState(null, '')
        }
    }

    onToggleNavBar = () => {
        document.getElementById("myNav").style.width = this.state.isNavOpen ? "0%" : "100%";
        this.setState({
            isNavOpen: !this.state.isNavOpen
        })
    }

    render() {
        return (
            <div>
                <Navbar
                    onToggleNavBar={this.onToggleNavBar}
                    activeMenuItem={'HOME'}
                    isNavOpen={this.state.isNavOpen}
                />
                <div style={styles.background}>
                    <Header />
                    <div id="home_section_2">
                        <Features
                            title="MYO MOVEMENT"
                            subtitle="Your Gateway to Personalised Wellbeing"
                            content="Myo Movement transcends the traditional Physiotherapy experience, offering a personalized pathway to enhanced health and wellness. This lifestyle club uniquely integrates Physiotherapy, Rehabilitation, and Personal Training within a comprehensive approach to wellness, emphasizing a holistic philosophy rather than a conventional one. Myo Movement is designed to nurture both physical and mental well-being, making it the ideal setting for transformative health practices."
                            // content2="At Myo Movement, we believe in the power of individualisation. Unlike one-size-fits-all physio/fitness programs, we understand that every person and their needs are unique. That's why we emphasize careful testing and customized treatment plans. Our team of experts works closely with you to understand your specific goals, challenges, and limitations, and then designs a program that's tailored just for you."
                            featureIcons={["/images/PHYSIOTHERAPY.svg", "/images/TRAINING.svg"]}
                            featureLabels={["HYBRID APPROACH", "INDIVIDUALISED CARE"]}
                            mainImage="/images/myomovment.png"
                            redirectionLinks="/physio#physio-form"
                            isButton={false}
                            style={{ height: '100%', width: '100%' }}
                            containerStyles={{ }}
                            fullContainerStyles={{ backgroundColor: 'transparent' }}
                        />
                    </div>
                    <Features
                        title="WHAT SETS MYO MOVEMENT APART"
                        subtitle="At Myo Movement"
                        content="We believe in the power of individualisation. Unlike a one-size-fits-all physio/fitness program, we understand that every person and their needs are unique. That is why we emphasize careful testing and customized treatment plans. Our team of experts work closely with you to understand your specific goals, challenges, and limitations, and then designs a program that's tailored just for you."
                        featureIcons={["/images/TRAINING.svg", "/images/welcoming_env.svg","/images/personalised.png"]}
                        featureLabels={["EXPERT TEAM", "WELCOMING ENVIRONMENT","PERSONALIZED PROGRAMS"]}
                        mainImage="/images/myo_apart.jpg"
                        redirectionLinks="/physio#physio-form"
                        isButton={false}
                        style={{ height: '80%', width: '100%' }}
                        containerStyles={{ }}
                        fullContainerStyles={{ backgroundColor: '#1D1D1D' , overflowX: 'hidden' }}
                    />
                    <Features
                        title="MYO TRAINING"
                        content="At Myo Movement, we believe that movement is medicine. Our multidisciplinary team of Physiotherapists, Manual Therapists, and Rehab Trainers crafts customized programs designed to strengthen, condition, and enhance resilience. This tailored approach ensures that each client progresses towards optimal health and fitness."
 //                     content2="Using our patented assessments, we create a roadmap for physical excellence, no matter who you are. With a wide range of movement specialists in our team (BJJ, functional training, animal flow, physiotheraphy), we are here to look after your fitness and wellness needs."
                        featureIcons={["/images/PHYSIOTHERAPY.svg", "/images/PHYSIOTHERAPY.svg","/images/PHYSIOTHERAPY.svg","/images/PHYSIOTHERAPY.svg"]}
                        featureLabels={["PHYSIOTHERAPY", "PERSONAL TRAINING","GROUP TRAINING","TREATMENT"]}
                        mainImage="/images/training_image.jpg"
                        redirectionLinks="/physio#physio-form"
                        isButton={false}
                        style={{ height: '100%', width: '100%' }}
                        containerStyles={{  }}
                        fullContainerStyles={{ backgroundColor: 'transparent' }}
                        />
                    <BookAConsult />
                    <Team />
                    <Testimonials/>
                    <Services
                        title="WHY MYO?"
                        subtitle="A SPECIALIZATION LIKE NO OTHER"
                        list={[{ icon: "/images/resutBased.png", label: "RESULTS-BASED" }, { icon: "/images/proven.png", label: "PROVEN" }, { icon: "/images/quality.png", label: "QUALITY" }, { icon: "/images/integratedApproach.png", label: "INTEGRATION" }]}
                    />
                    <Footer
                        isInstaVisible={true}
                    />
                </div>
            </div >
        )
    }
}

const styles = {
    background: { backgroundImage: "linear-gradient(180deg, #000000 0%, #424242 100%)", backgroundAttachment: 'fixed' },

}

export default Home;