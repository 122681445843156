import React from 'react';
// import { useHistory } from "react-router";


function Header(props) {
    // const history = useHistory();

    return (
        <div>
            <div style={styles.headerBackground}>
                <div style={styles.headerBackgroundOverlay}>
                    <div className="container d-flex flex-column justify-content-center align-items-center h-100">
                        <h1 className="header_title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">MYO PHYSIO</h1>
                        <p className="header_content mt-4" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">Our physiotherapists will conduct a thorough assessment to<br /> understand your health condition to then tailor a recovery program.</p>
                        {/* <a href="#physio-form" className="header_btn mt-3" style={{ color: 'black', textDecoration: 'none' }} data-aos-once={true} data-aos="fade-up" data-aos-duration="600"><div><p>GET STARTED</p></div></a> */}
                        <div style={{ position: 'absolute', bottom: 50, fontWeight: 'bold', fontSize: '18px' }}>
                            <p style={{ color: '#fff' }}>GET IN TOUCH</p>
                        </div>
                        <div style={styles.headerBottomImage}>
                            <img src="/images/down_arrow.svg" style={{ height: '70%', width: '80%', marginLeft: '13%' }} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-baseline">
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    headerBackground: { backgroundImage: "url(/images/physio_hero.jpg)", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh' },
    headerBackgroundOverlay: { backgroundColor: ' rgba(0,0,0,0.6)', zIndex: 2, height: '100vh' },
    headerBottomContent: { position: 'absolute', bottom: 50 },
    headerBottomImage: { position: 'absolute', bottom: 30 }
}

export default Header;