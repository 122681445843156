import React from 'react';
import './Styles.css'

function Navbar(props) {
    return (
        <div>
            <div id="myNav" className="overlay">
                <nav className="navbar navbar-light fixed-top headerNav">
                    <div>
                        {/* <a href="/"><img src="/images/logo.svg" style={{ marginLeft: '35px', width: '45px', height: '45px' }} className="mt-2" /></a> */}
                        {/* <p style={{ color: '#fff' }} > MYO MOVEMENT </p> */}
                    </div>
                    <a href="javascript:void(0)" className="closebtn" onClick={() => { props.onToggleNavBar() }}>×</a>
                </nav>
                <div className="overlay-content">
                    <div className="row" style={styles.navBarContentContainer}>
                        <div className="col-md-3">
                            <a href="/" style={{ textDecoration: 'none' }}>
                                <div className="nav_item_container">
                                    <div id="menu-item" className={props.activeMenuItem === "HOME" ? "focus" : ""} data-aos="zoom-in" data-aos-delay="600" data-aos-duration="600" data-aos-once={true}>
                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                                            <img src="/images/menu_logo.svg" className="nav_item_icon" />
                                            <p>HOME</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="/physio" style={{ textDecoration: 'none' }}>
                                <div className="nav_item_container">
                                    <div id="menu-item" className={props.activeMenuItem === "PHYSIO" ? "focus" : ""} data-aos="zoom-in" data-aos-delay="600" data-aos-duration="600" data-aos-once={true}>
                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                                            <img src="/images/menu_physio.svg" className="nav_item_icon" />
                                            <p>PHYSIOTHERAPY</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3">
                            <a href="/train" style={{ textDecoration: 'none' }}>
                                <div className="nav_item_container">
                                    <div id="menu-item" className={props.activeMenuItem === "TRAIN" ? "focus" : ""} data-aos="zoom-in" data-aos-delay="1600" data-aos-duration="600" data-aos-once={true}>
                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                                            <img src="/images/menu_train.svg" className="nav_item_icon" style={{
                                                height:'22%',width:'22%'
                                            }} />
                                            <p>TRAINING</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-md-3 mb-5">
                            <a href="/contactus" style={{ textDecoration: 'none' }}>
                                <div className="nav_item_container">
                                    <div id="menu-item" className={props.activeMenuItem === "CONTACT" ? "focus" : ""} data-aos="zoom-in" data-aos-delay="1600" data-aos-duration="600" data-aos-once={true}>
                                        <div className="d-flex flex-column justify-content-center align-items-center pt-3">
                                            <img src="/images/menu_contact.svg" className="nav_item_icon" />
                                            <p>CONTACT US</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {!props.isNavOpen ?
                <nav className="navbar navbar-light fixed-top headerNav">
                    <span style={{...styles.navBar,fontSize: '42px',marginBottom:'2px'}} className='d-sm-block d-md-none' onClick={() => { props.onToggleNavBar(); }}>☰</span>
                    <div  className='d-none d-md-block d-lg-block ml-4'></div>
                    <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#fff' }}>
                        <img src="/images/logo.svg" style={{ width: '35px', height: '35px'}} />
                        <span className='d-none d-md-block d-lg-block' style={{ marginLeft: '5px', padding: '5% 35px',fontWeight:'bold',fontSize:'18px' }}>MYO MOVEMENT</span>
                    </a>
                    {/* <span style={styles.navBar} className="ml-auto" onClick={() => { props.onToggleNavBar(); }}>☰</span> */}
                    <div className="ml-auto">
                        <div className='d-none d-md-block d-lg-block'>
                        <div className='d-flex align-items-center'>
                        <a href="/physio#physio-form" style={{ marginRight: '50px', fontSize:'14px', textDecoration: 'none', fontWeight: 'bold', color: 'black', background: '#fff', padding: '10px 30px' }}>
                            BOOK NOW
                        </a>
                        <span style={styles.navBar} onClick={() => { props.onToggleNavBar(); }}>☰</span>
                        </div>
                        </div>
                        <div className='d-block d-md-none d-lg-none'>
                        <a href="/physio#physio-form" style={{ textDecoration: 'none', fontSize:'12px',fontWeight: 'bold', color: 'black', background: '#fff', padding: '8px 16px' }}>
                        BOOK NOW
                        </a>
                        </div>
                    </div>
                </nav>
                :
                null
            }
        </div>
    )
}

const styles = {
    navBarContentContainer: { marginLeft: '10%', marginRight: '10%' },
    navBar: { fontSize: '30px', cursor: 'pointer', color: '#FFFFFF', marginRight: '35px',marginBottom:'10px' }
}

export default Navbar;