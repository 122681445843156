import React, { Component } from 'react';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={{ backgroundColor: 'transparent' }}>
                {this.props.isInstaVisible ?
                    <div className="pt-5">
                        <div className="container mt-5 mb-5">
                            <h1 className="bc_title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">STAY IN TOUCH</h1>
                            <p className="bc_content mt-4" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">Join the Myo Movement community to stay up to date about the exciting programs, sessions and activities that we have in store.</p>
                            <a href="https://www.instagram.com/myomovementindia/?hl=en" target="_blank" style={{ color: 'black', textDecoration: 'none' }}>
                                <div className="bc_btn mx-auto mt-4" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">
                                    <div className="row">
                                        <div className="ml-3">
                                            <img src="/images/instagram.svg" style={{ height: '2rem', width: '2rem' }} />
                                        </div>
                                        <div style={{ margin: 0 }} className="mx-auto my-auto">
                                            <p style={{ margin: 0 }} className="hash_tag_text">@MYOMOVEMENTINDIA</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            {/* <div className="bc_btn mx-auto mt-4">
                        <div className="d-flex flex-row">
                            <div>
                                <img src="/images/instagram.svg" style={{ height: '2rem', width: '2rem' }} />
                            </div>
                            <div className="d-flex mx-auto align-items-center justify-content-center">
                                <p style={{ margin: 0 }}>@MYOMOVEMENTINDIA</p>
                            </div>
                        </div>
                    </div>
                    <div className="bc_btn mx-auto mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <img src="/images/instagram.svg" style={{ height: '2rem', width: '2rem' }} />
                            </div>
                            <div className="col-md-8 mt-1">
                                <p>@MYOMOVEMENTINDIA</p>
                            </div>
                        </div>
                    </div> */}
                        </div>
                    </div>
                    : null}
                <div className="pt-5 pb-2" style={{ textAlign: 'center', color: 'white' }}>
                    <p>COPYRIGHT @MYOMOVEMENT.IN</p>
                </div>
            </div >

        )
    }
}

const styles = {

}

export default Footer;