import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './Screens/Home';
import Error from './Screens/Error';
import Training from './Screens/Training';
import Contact from './Screens/Contact';
import Physio from './Screens/Physio';
import MentorSessions from './Screens/MentorSession';
import PrivateSessions from './Screens/PrivateSession';
import PaymentSuccess from './Screens/PaymentSuccess';
class App extends Component {
  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          {/* <Navigation /> */}
          <Switch>
            <Route path="/" component={Home} exact /> 
            {/* <Route path="/" component={PaymentSuccess} exact /> */}
            <Route path="/train" component={Training} exact />
            <Route path="/contactus" component={Contact} exact />
            <Route path="/physio" component={Physio} exact />
            <Route path="/mentor-session" component={MentorSessions} exact />
            <Route path="/private-session" component={PrivateSessions} exact />
            <Route path="/payment-success" component={PaymentSuccess} exact />
            <Route component={Error} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;