import React from 'react';

function Header(props) {
    return (
        <div>
            <div style={styles.headerBackground}>
                <div className="d-flex align-items-center" style={{ height: '100%' }}>
                    <div className="container">
                        <div onClick={() => { window.history.go(-1) }} style={{ cursor: 'pointer' }}>
                            <img src='/images/back_arrow.svg' className="back_arrow" />
                        </div>
                        <h1 className="session_title mt-5" data-aos="fade-up" data-aos-duration="600">
                            {props.title}
                        </h1>
                        <div className='row mt-4'>
                            <div className='col-md-8'>
                                <p className='session_sub_text' data-aos="fade-up" data-aos-duration="600">
                                    Myomoevemnt has been the day that you are the same and I can not understand how much to the child and the child is not the same but I am not going to call the appointment and I will be back in the morning to see what you were doing and how did I get to know you better at times I can
                                </p>
                                <p className='session_sub_text' data-aos="fade-up" data-aos-duration="600">
                                    Myomoevemnt has been the day that you are the same and I can not understand how much to the child and the child is not the same but I am not going to call the appointment and I will be back in the morning to see what you were doing and how did I get to know you better at times I className
                                </p>
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className="row">
                                <div className="col-md-5 mt-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                    <a href="#session_form" style={{ textDecoration: 'none', color: 'black' }} className="physio_submit_button" data-aos="fade-up" data-aos-duration="600">
                                        <p className="mt-3 physio_submit_button_text">REGISTER</p>
                                    </a>
                                </div>
                                <div className="col-md-7 mt-3">
                                    <p className="physio_submit_warning" data-aos="fade-up" data-aos-duration="600">
                                        <span>*Note</span><br />
                                        Myomoevemnt has been the day that you are the same and I can not understand how much to the child and the child is being very helpful
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const styles = {
    headerBackground: { backgroundImage: "linear-gradient(180deg, #000000 0%, #424242 100%)", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh' },
}

export default Header;