import React, { Component } from 'react';

class Error extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div>
                <h1>404 - page not found</h1>
            </div>
        )
    }
}

const styles = {

}

export default Error;