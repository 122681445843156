import React, { Component } from 'react';
import Features from '../Components/Common/Features';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import Services from '../Components/Common/Services';
import Team from '../Components/Common/Team';
import Header from '../Components/Train/Header';

import './TrainStyles.css'

class Training extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
    }

    onToggleNavBar = () => {
        document.getElementById("myNav").style.width = this.state.isNavOpen ? "0%" : "100%";
        this.setState({
            isNavOpen: !this.state.isNavOpen
        })
    }

    render() {
        return (
            <div>
                <Navbar
                    onToggleNavBar={this.onToggleNavBar}
                    activeMenuItem={'TRAIN'}
                    isNavOpen={this.state.isNavOpen}
                />
                <div style={styles.background}>
                    <Header />
                    <div id="training_section_2">
                        <Features
                            title="MOVEMENT IS MEDICINE"
                            content="Begin your journey with a personally appointed team of trainers that help you build lean muscle, explore untapped ranges of motion and teach you varied movement skills."
                            featureIcons={["/images/PHYSIOTHERAPY.svg", "/images/PHYSIOTHERAPY.svg"]}
                            featureLabels={["MENTORSHIP PROGRAM", "PERSONAL TRAINING"]}
                            mainImage="/images/movmentisMdeicene.jpg"
                            redirectionLinks="/physio#physio-form"
                            isButton={false}
                            style={{ height:'400px' }}
                            containerStyles={{  }}
                        />
                    </div>
                    <Services
                        title="OUR FOCUS"
                        subtitle="AN ENSEMBLE LIKE NEVER BEFORE. EXPLORE OUR PACKAGE"
                        list={[{ icon: "/images/physical_therapy.png", label: "PHYSIOTHERAPY" }, { icon: "/images/TRAINING.svg", label: "TRAINING" }, { icon: "/images/rehab_new.png", label: "REHABILITAION" }, { icon: "/images/injury_rehab.png", label: "ASSESSMENT" }]}
                    />
                    <Features
                        title="MENTORSHIP PROGRAM"
                        content="Myo Movement offers group mentorship sessions to enhance strength, motion and recovery. Reach out to us to understand how mentorship sessions could benefit your well-being."
                        featureIcons={["/images/PHYSIOTHERAPY.svg", "/images/PHYSIOTHERAPY.svg"]}
                        featureLabels={["GROUP SESSIONS", "IN PERSON SESSIONS"]}
                        mainImage="/images/mentorshipProgram.jpg"
                        redirectionLinks="/mentor-session"
                        isButton={false}
                        style={{ height:'400px' }}
                        containerStyles={{ }}
                    />
                    <Features
                        title="PERSONAL TRAINING"
                        content="At Myo, we understand that everybody's needs are unique. Our specialised, tailor made fitness programs place a deep focus on your individual needs and goals. Programs evolve alognside you as we track your progress every step of the way."
                        featureIcons={["/images/PHYSIOTHERAPY.svg", "/images/PHYSIOTHERAPY.svg"]}
                        featureLabels={["MENTOR SESSIONS", "PERSONAL SESSIONS"]}
                        mainImage="/images/personal_training.jpg"
                        redirectionLinks="/private-session"
                        isButton={false}
                        style={{ height:'400px' }}
                        containerStyles={{ }}
                        fullContainerStyles={{backgroundColor: '#292929'}}
                    />
                    {/* <Services
                        title="MYO METHODS?"
                        // subtitle="AN ENSEMBLE LIKE NEVER BEFORE. EXPLORE OUR PACAKGE"
                        subtitle="DESIGNED BY EXPERTS"
                        list={[{ icon: "/images/physical_therapy.png", label: "PHYSIOTHERAPY" }, { icon: "/images/TRAINING.svg", label: "TRAINING" }, { icon: "/images/rehab_new.png", label: "REHABILITAION" }, { icon: "/images/injury_rehab.png", label: "ASSESSMENT" }]}
                    /> */}
                    {/* <Team /> */}
                    <Footer
                        isInstaVisible={true}
                    />
                </div>
            </div>
        )
    }
}

const styles = {
    background: { backgroundImage: "linear-gradient(180deg, #000000 0%, #424242 100%)", backgroundAttachment: 'fixed' },
}

export default Training;