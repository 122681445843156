import React, { Component } from 'react';
import Navbar from '../Components/Common/Navbar';
import DropDown from '../Components/Common/DropDown';
import FileUpload from '../Components/Common/FileUpload';
import Header from '../Components/Physio/Header';
import InputBox from '../Components/Common/InputBox';

import './PhysioStyles.css'
import apicaller from '../utils/ApiCaller';
import Swal from 'sweetalert2';
import AppointmentCalendar from '../Components/Common/AppointmentCalendar';
import moment from 'moment';

class Physio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            genderOptions: ["Male", "Female", "Others"],
            selectedGender: '',
            ageOptions: [1, 2, 3, 4],
            selectedAge: '',
            physiotherapyOptions: ["Physiotherapy", "Training"],
            selectedPhysiotherapy: '',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            scansLink: '',
            issue: '',
            isSubmittingForm: false,
            selectedCalanderDate: '',
            // mriScans: {},
            // mriScansUrl: [],
            // xrayScan: {},
            // xrayScanUrl: [],
            // doctorsPrescription: {},
            // doctorsPrescriptionUrl: [],
            // otherDocuments: {},
            // otherDocumentsUrl: []
        };
    }

    onToggleNavBar = () => {
        document.getElementById("myNav").style.width = this.state.isNavOpen ? "0%" : "100%";
        this.setState({
            isNavOpen: !this.state.isNavOpen,
        })
    }

    handleOnSelectDropdown = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    // handleUpload = (key, e) => {
    //     let selectedImages = e.target.files;
    //     let finalUrls = [];
    //     let finalImages = [];
    //     for (let i in selectedImages) {
    //         if (i != "length" && i != "item") {
    //             finalImages.push(selectedImages[i]);
    //             finalUrls.push(URL.createObjectURL(selectedImages[i]));
    //         }
    //     }
    //     this.setState({
    //         [key]: selectedImages,
    //         [key + "Url"]: finalUrls
    //     }, () => { console.log("bhanu here", this.state.mriScansUrl, this.state) })
    // }

    // handleOnClickUpload = (keyName) => {
    //     document.getElementById(keyName).click();
    // }


    handleOnChangeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleValidation = () => {
        let output = {};
        output.message = '';
        output.isValid = true;
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.state.first_name || this.state.first_name == "") {
            output.message = "Please enter name.";
            output.isValid = false;
            return output;
        }
        if (!this.state.selectedGender || this.state.selectedGender == "") {
            output.message = "Please select gender.";
            output.isValid = false;
            return output;
        }
        if (!this.state.phone || this.state.phone == "" || !rp.test(this.state.phone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number.";
            return output;
        }
        if (!this.state.email || this.state.email == "" || !re.test(this.state.email)) {
            output.isValid = false;
            output.message = "Please enter a valid email.";
            return output;
        }
        if (!this.state.selectedAge || this.state.selectedAge == "") {
            output.message = "Please select age.";
            output.isValid = false;
            return output;
        }
        if(!this.state.selectedCalanderDate?.start){
            output.message = "Please select a date for appointment.";
            output.isValid = false;
            return output;
        }
        // issue
        if (!this.state.issue || this.state.issue == "") {
            output.message = "Please enter condition.";
            output.isValid = false;
            return output;
        }
        // consultationType
        if (!this.state.selectedPhysiotherapy || this.state.selectedPhysiotherapy == "") {
            output.message = "Please select consultation type.";
            output.isValid = false;
            return output;
        }
        return output;
    }

    handleOnSubmit = () => {
        let validation = this.handleValidation();
        if (validation.isValid) {
            this.setState({
                isSubmittingForm: true
            })
            let data = {};
            data.firstName = this.state.first_name;
            data.lastName = this.state.last_name;
            data.gender = this.state.selectedGender;
            data.email = this.state.email;
            data.mobile = `+91${this.state.phone}`;
            data.age = parseInt(this.state.selectedAge);
            data.consultationType = this.state.selectedPhysiotherapy;
            data.issueBrief = this.state.issue;
            data.appointmentDate = this.state.selectedCalanderDate.start;
            data.eventObject    = this.state.selectedCalanderDate;


            // let data = {
            //     "fields": {
            //         "Name": {
            //             "value": this.state.first_name + " " + this.state.last_name
            //         },
            //         "Age": {
            //             "value": parseInt(this.state.selectedAge)
            //         },
            //         "Gender": {
            //             "value": this.state.selectedGender
            //         },
            //         "Email": {
            //             "value": this.state.email
            //         },
            //         "Mobile_Number": {
            //             "value": this.state.phone,
            //             "code": "91"
            //         },
            //         "Enquiry_Type": {
            //             "value": this.state.selectedPhysiotherapy
            //         },
            //         "Training_Preference": {
            //             "value": this.state.selectedPhysiotherapy
            //         },
            //         "Goals": {
            //             "value": this.state.issue
            //         },
            //         "Scans": {
            //             "value": this.state.scansLink
            //         },
            //         "Enquiry_Status": {
            //             "value": "New"
            //         }
            //     }
            // }
            apicaller('physio-appointments/book-appointment','POST', data)
                .then(res => {
                    console.log("API RESPONSE",JSON.stringify(res.data));
                    if (res.data.status === "success") {
                        // Swal.fire('Submitted!', `We received you'r request we will get back to you soon.`, 'success')
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        const { url } = res.data.paymentData; // Assuming the redirect URL is provided in the response data
                        // Redirect the browser to the provided URL
                        window.location.href = url;
                        // this.props.history.push({
                        //     pathname: '/',
                        //     state: { isReload: true }
                        // })
                    }
                    this.setState({
                        isSubmittingForm: false
                    })
                })
                .catch(err => {
                    Swal.fire('Error!', `some internal issue.`, 'error')
                    console.log(err);
                    this.setState({
                        isSubmittingForm: false
                    })
                })
        }
        else {
            Swal.fire('Warning!', validation.message, 'warning')
        }
    }

    render() {
        return (
            <div >
                <Navbar
                    onToggleNavBar={this.onToggleNavBar}
                    activeMenuItem={'PHYSIO'}
                    isNavOpen={this.state.isNavOpen}
                />
                <div style={styles.background}>
                    <Header />
                    <div style={{ backgroundColor: 'transparent' }} id="physio-form">
                        <div className="container pt-5 pb-5" >
                            <h1 className="physio_title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">GET IN TOUCH</h1>
                            <p className="physio_content" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">Please fill this form so you can get exactly what you need.</p>
                            <label className="physio_label mt-5" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">PERSONAL DETAILS</label>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                if (!this.state.isSubmittingForm) {
                                    this.handleOnSubmit()
                                }
                            }}
                                data-aos-once={true} data-aos="fade-up" data-aos-duration="600"
                            >
                                <div >
                                    <div className="row" >
                                        <div className="col-md-4 mt-3">
                                            <InputBox
                                                placeholder="FIRST NAME"
                                                keyName='first_name'
                                                handleOnChangeValue={this.handleOnChangeValue}
                                                value={this.state.first_name}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <InputBox
                                                placeholder="LAST NAME"
                                                keyName='last_name'
                                                handleOnChangeValue={this.handleOnChangeValue}
                                                value={this.state.last_name}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3" >
                                            <DropDown
                                                options={this.state.genderOptions}
                                                value={this.state.selectedGender}
                                                defaultValue="GENDER"
                                                keyName="selectedGender"
                                                handleOnSelectDropdown={this.handleOnSelectDropdown}
                                            />
                                        </div>
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-4 mt-3">
                                            <InputBox
                                                placeholder="MOBILE NUMBER"
                                                keyName='phone'
                                                handleOnChangeValue={this.handleOnChangeValue}
                                                value={this.state.phone}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <InputBox
                                                placeholder="EMAIL ID"
                                                keyName='email'
                                                handleOnChangeValue={this.handleOnChangeValue}
                                                value={this.state.email}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3" >
                                            <InputBox
                                                placeholder="AGE"
                                                keyName='selectedAge'
                                                handleOnChangeValue={this.handleOnChangeValue}
                                                value={this.state.selectedAge}
                                            />
                                        </div>
                                    </div>
                                    <div className="pt-5" >
                                        <label className="physio_label mt-3">WHAT CONSULTATION DO YOU WANT?</label>
                                        <div className="row pt-3" >
                                            <div className="col-md-4" >
                                                <DropDown
                                                    options={this.state.physiotherapyOptions}
                                                    value={this.state.selectedPhysiotherapy}
                                                    defaultValue="CONSULTATION TYPE"
                                                    keyName='selectedPhysiotherapy'
                                                    handleOnSelectDropdown={this.handleOnSelectDropdown}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <label className="physio_label mt-3">ATTACH RELEVANT DOCUMENTS (OPTIONAL)</label>
                                        <div className="row">
                                            <div className="col-md-4 mt-3">
                                                <InputBox
                                                    placeholder="LINK"
                                                    keyName='scansLink'
                                                    handleOnChangeValue={this.handleOnChangeValue}
                                                    value={this.state.scansLink}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                    <div className="col-md-3 mt-3">
                                        <FileUpload
                                            displayName="MRI SCANS"
                                            keyName="mriScans"
                                            handleOnClickUpload={this.handleOnClickUpload}
                                            handleUpload={this.handleUpload}
                                            images={this.state.mriScansUrl}
                                        />
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <FileUpload
                                            displayName="XRAY SCANS"
                                            keyName="xrayScan"
                                            handleOnClickUpload={this.handleOnClickUpload}
                                            handleUpload={this.handleUpload}
                                            images={this.state.xrayScanUrl}
                                        />
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <FileUpload
                                            displayName="DOCTORS PRESCRIPTIONS"
                                            keyName="doctorsPrescription"
                                            handleOnClickUpload={this.handleOnClickUpload}
                                            handleUpload={this.handleUpload}
                                            images={this.state.doctorsPrescriptionUrl}
                                        />
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <FileUpload
                                            displayName="OTHER DOCUMENTS"
                                            keyName="otherDocuments"
                                            handleOnClickUpload={this.handleOnClickUpload}
                                            handleUpload={this.handleUpload}
                                            images={this.state.otherDocumentsUrl}
                                        />
                                    </div>
                                </div>
                             */}
                                    </div>
                                    <div className="mt-5">
                                        <label className="physio_label mt-3">EXPLAIN IN BRIEF WHAT YOUR CONDITION IS</label>
                                        <div className="mt-3">
                                            <textarea className="physio_textarea" placeholder="TYPE HERE" onChange={(e) => { this.handleOnChangeValue('issue', e.target.value) }}>{this.state.issue}</textarea>
                                        </div>
                                    </div>

                                    <div className="mt-5">
                                    <label className="physio_label mt-3">SELECT SLOT</label>
                                        <AppointmentCalendar selectedDate={(date) => { this.setState({
                                            selectedCalanderDate: date
                                        }) }}></AppointmentCalendar>        
                                        <p className="physio_label" style={{
                                            fontSize:'18px'
                                        }}> {this.state?.selectedCalanderDate?.start ? `You have chosen ${moment(this.state.selectedCalanderDate.start).format('Do MMMM YYYY [at] LT') } for your appointment. Please proceed to confirm.` : ""} </p>                                
                                    </div>

                                    <div className="mt-5">
                                        <div className="row">
                                            <div className="col-md-4 mt-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                <button className="physio_submit_button">
                                                    <p className="mt-3 physio_submit_button_text">{this.state.isSubmittingForm ? "SUBMITTING...." : "SET UP AN APPOINTMENT"}</p>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-8 mt-3">
                                                <p className="physio_submit_warning">
                                                    Note: <br />Your data is not shared with any third party, Your information is secure within the Myo Movement ecosystem.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    background: { backgroundImage: "linear-gradient(180deg, #000000 0%, #424242 100%)", backgroundAttachment: 'fixed' },
}

export default Physio;