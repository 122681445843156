import React, { Component } from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import moment from 'moment';

import './PaymentSuccessStyles.css'

class PaymentSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
            name : "",
            timeSlot : "",
            email : "",
        };
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const name = queryParams.get('name');
        const timeSlot = queryParams.get('timeSlot');
        const email = queryParams.get('email');
        const appointmentId = queryParams.get('appointmentId');
        const paymentAmount = queryParams.get('amount');
        const bookingType = queryParams.get('bookingType');

        this.setState({
            name: name,
            timeSlot: timeSlot,
            email: email,
            appointmentId : appointmentId,
            paymentAmount : paymentAmount,
            bookingType : bookingType
        });
    }

    onToggleNavBar = () => {
        document.getElementById("myNav").style.width = this.state.isNavOpen ? "0%" : "100%";
        this.setState({
            isNavOpen: !this.state.isNavOpen
        })
    }

    render() {

        const name = this.state.name;
        const timeSlot = this.state.timeSlot;
        const location = "Myo Movement, Hyderabad Office";
        const email = this.state.email ; // This could also be dynamic

        return (
            <div>
                <Navbar
                    onToggleNavBar={this.onToggleNavBar}
                    isNavOpen={this.state.isNavOpen}
                />
                <div>
                    <div style={styles.wrapper}>
                        <div style={styles.headerBackground}>
                            <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
                                <h1 className="header_title">Booking Success</h1>
                                <img src="/images/proven.png" style={{ height: '100px', width: '100px' }} />
                                <p className="success_message">
                                    Congratulations, {this.state.name} ! Your appointment booking and payment have been successfully processed.
                                </p>
                                <div className="appointment_details">
                                    <p>This is your time slot: <strong>{timeSlot}</strong></p>
                                    <p>Location to reach: <strong>
                                        <a href="https://www.google.com/maps/dir/?api=1&destination=latitude,longitude"
                                            target="_blank"
                                            style={{ color: '#007bff', textDecoration: 'none', cursor: 'pointer' }}
                                            onMouseOver={(e) => e.currentTarget.style.textDecoration = 'underline'}
                                            onMouseOut={(e) => e.currentTarget.style.textDecoration = 'none'}>
                                            {location}
                                        </a>
                                    </strong></p>
                                    {/* <p>Appointment ID: <strong>{this.state.appointmentId}</strong></p>
                                    <p>Payment Amount: <strong>{this.state.paymentAmount}</strong></p> 
                                    <p>Booking Type: <strong>{this.state.bookingType}</strong></p> */}
                                    <p>A confirmation has been sent to your email: <strong>{email}</strong></p>
                                    <p>Thank you for choosing us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer
                        isInstaVisible={true}
                    />
                </div>
            </div>
        )
    }
}

const styles = {
    wrapper: {
        textAlign: 'center',
        color: 'white',
    },
    headerBackground: {
        backgroundImage: "linear-gradient(180deg, #000000 0%, #424242 100%)",
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
}

export default PaymentSuccess;