import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Styles.css";

export default function Testimonials() {
  const testominialData = [
    {
      id: 1,
      name: "MAHESH BABU",
      image: "./images/testimonials/mahesh_babu.png",
      content: `I've had the privilege of working with some of the best trainers in the world in my quest for fitness, and Minash Gabriel tops that list.`,
    },
    {
      id: 2,
      name: "TEJA VADLAMANI",
      image: "./images/testimonials/teja.png",
      content: `I am thrilled to share my positive experience with Minash. For seven years, I had been suffering from lower back pain and had visited multiple physiotherapists and orthopedists in search of a permanent solution. Minash's holistic approach to healthcare is remarkable.
      His dedication and expertise in providing long-lasting solutions for his patients are evident in the way he goes above and beyond to understand their needs and develop a personalized treatment plan.`,
    },
    {
      id: 3,
      name: "DR TEJASWINI",
      image: "./images/testimonials/tejaswini.png",
      content: `I came to Myo with a knee injury and very little confidence, but with the help of the team at Myo and Minash, I am now pain free and back to training!`,
    },
    {
      id: 4,
      name: "MR. PAL",
      image: "./images/testimonials/pal.png",
      content: `After thorough research we zeroed in on Myo
      Movement & Dr. Minash and it was undoubtedly a great choice. From the fantastic movement therapy programming to the knowledge and experience we saw great progress.`,
    },
    {
      id: 5,
      name: "SREEJA KONIDELA",
      image: "./images/testimonials/sreja.png",
      content: `Myo, my happy space! Training with minash is exceptionally motivating. His expertise and experience come to play as he customises the training program to suit my daily emotional and physical wellbeing. I’m achieving fitness goals that I never thought were possible. I’ve gained a newfound confidence in myself. I’ve incorporated some profound movements that I learned at myo to my daily routine that let’s by body move with ease. Thank to minash who makes sure i walk out of the gym with a heart full smile everyday.`,
    },
    {
      id: 6,
      name: "SANGEETHA",
      image: "./images/testimonials/sangeetha.png",
      content: `I love coming to Myo, it’s like home away from home!`,
    },
    {
      id: 7,
      name: "SHAILENDAR",
      image: "./images/testimonials/shaildener.png",
      content: `I see a huge improvement in my cardiovascular ability after consistently attending the group classes at Myo. What I thought was impossible, running a 10k - I finished at my best of 85 minutes. Highly recommend Myo Movement!`,
    },
    {
      id: 8,
      name: "WILLIAM MARSDENJ",
      image: "./images/testimonials/william.png",
      content: `As a former professional rugby player I underwent two back surgeries in the past and carried a bunch of postural asymmetries.
      Within the first month of rehab, I began to notice his pain steadily drop from an 8/10 to a 2/10 and a few weeks later stop altogether.`,
    },
    {
      id: 9,
      name: "RAVIKANTH KAJA",
      image: "./images/testimonials/ravi.png",
      content: `With Minash's guidance, training and treatment, 3 months of rehab later I am glad to say that I have started working out again, I am doing squats, I can also drive long distances without any pain. Basic everyday movements that were difficult, I can now do with ease.`,
    },
    {
      id: 10,
      name: "DINESH BANDA",
      image: "./images/testimonials/dinesh.png",
      content: `I live in the US and was visiting my family Hyderabad, so my time in India was precious.
      After the initial consult with Minash, I knew my decision had been vindicated. You can not find a better combination of people, service and facility if you are serious about your recovery.
      I've been to a few PT facilities prior and Myo is the gem that genuinely cares about whether its clients achieve their goal-oriented recoveries.`,
    },
  ];

  return (
    <div className="pt-2 pb-2" style={{ backgroundColor: "#1D1D1D" }}>
      <div className="container mb-5 mt-5  centered-content">
        <h1
          className="about_title ml-2"
          data-aos-once={true}
          data-aos="fade-up"
          data-aos-duration="600"
        >
          TESTIMONIALS
        </h1>
        <p
          className="about_sub_title ml-2"
          data-aos-once={true}
          data-aos="fade-up"
          data-aos-duration="600"
        >
          Client stories
        </p>
        <div
          className="mt-4"
          data-aos-once={true}
          data-aos="fade-right"
          data-aos-duration="600"
        >
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6100}
          >
            {testominialData.map((item, index) => (
              <div className="row mb-5 pl-4 pr-4">
                <div className="col-md-4">
                  <img
                    src={item.image}
                    alt=""
                    style={{
                      width: "180px",
                      height: "180px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                  <h4 className="mt-3 testimonial-name">{item.name}</h4>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                  <p
                    className="mt-2 pl-4 pr-4 bc_content"
                    style={{
                      color: "#FFF",
                      lineHeight: 1.8,
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              </div>
            ))}
            {/* <div className="row mb-5 pl-4 pr-4">
            <div className="col-md-4">
              <img
                src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1712620800&semt=sph"
                alt=""
                style={{
                  width: "180px",
                  height: "180px",
                  borderRadius: "50%",
                  objectFit:'cover'
                }}
              />
              <h4
                className="mt-3 testimonial-name"
              >
                M.Bhanu teja
              </h4>
            </div>
            <div className="col-md-8 d-flex align-items-center">
              <p
                className="mt-2 pl-4 pr-4 bc_content"
                style={{
                  color: "#FFF",
                  lineHeight:1.8
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </div>
          <div className="row mb-5 pl-4 pr-4">
            <div className="col-md-4">
              <img
                src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1712620800&semt=sph"
                alt=""
                style={{
                  width: "180px",
                  height: "180px",
                  borderRadius: "50%",
                  objectFit:'cover'
                }}
              />
              <h4
                className="mt-3 testimonial-name"
              >
                M.Bhanu teja
              </h4>
            </div>
            <div className="col-md-8 d-flex align-items-center">
              <p
                className="mt-2 pl-4 pr-4 bc_content"
                style={{
                  color: "#FFF",
                  lineHeight:1.8
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
            </div>
          </div> */}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
