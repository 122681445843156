import React from "react";
import "./Styles.css";

function Header(props) {
  return (
    <div>
      <div style={styles.headerBackground}>
        <div style={styles.headerBackgroundOverlay}>
          <div className="d-flex flex-column justify-content-center align-items-center h-100">
            {/* <img src="/images/header_logo.svg" style={{ height: '30%', width: '30%' }} data-aos="fade-up" data-aos-duration="600" data-aos-once={true} /> */}
            <h1
              style={styles.headerText}
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-once={true}
            >
              MOVEMENT IS THE MEDICINE TO CREATE A POSITIVE CHANGE IN A PERSON'S PHYSICAL, EMOTIONAL, AND MENTAL HEALTH.
            </h1>
            {/* <h1
              style={styles.headerText}
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-once={true}
            >
              PHYSICAL, EMOTIONAL AND MENTAL STATE{" "}
            </h1> */}
            <a
              style={{
                position: "absolute",
                bottom: 50,
                fontWeight: "bold",
                fontSize: "18px",
              }}
              href="#home_section_2"
            >
              <p style={{ color: "#fff",textAlign:'center' }}>
              INFORMED MOVEMENT, SUPERIOR RESULTS
              </p>
            </a>
            <a style={styles.headerBottomImage} href="#home_section_2">
              <img
                src="/images/down_arrow.svg"
                style={{ height: "70%", width: "80%", marginLeft: "13%" }}
              />
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-baseline"></div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  headerBackground: {
    backgroundImage: "url(/images/home_1.jpg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100vh",
  },
  headerBackgroundOverlay: {
    backgroundColor: " rgba(0,0,0,0.3)",
    zIndex: 2,
    height: "100vh",
  },
  headerBottomContent: { position: "absolute", bottom: 50 },
  headerBottomImage: { position: "absolute", bottom: 30 },
  headerText: {
    color: "#fff",
    fontSize: `${window.innerWidth < 480 ? "20px" : "40px"}`,
    fontWeight: "bold",
    textAlign: "center",
    paddingLeft:'10%',
    paddingRight:'10%'
  },
};

export default Header;
