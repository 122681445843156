import axios from "axios";
// export const BASE_URL = "http://192.168.29.212:3333/api/v1/";
// export const BASE_URL = "http://192.168.0.102:3333/api/v1/";
// export const BASE_URL = "https://stg3.hooperlabs.com/api/hook/61278686bfcb400c014290b4";
export const BASE_URL = "https://api.myomovement.in/";
// export const BASE_URL = "http://10.37.27.52:5000/";

export default async function apicaller(api,method, data) {
    var data = JSON.stringify(data);

    console.log("api",`${BASE_URL}${api}`);
    console.log("method",`${method}`);

    var config = {
        method: method,
        url: `${BASE_URL}${api}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2MTI3OGE3OWJmY2I0MDBjMDE0MjkwY2IiLCJleHAiOjE3NjcyMjU1NDAsImlhdCI6MTYwOTQ1OTIwMH0.tlhVeMRKxnYiFDEN_DsAl_FArlHsjkFbUjC801VmpI_nv2Nh5dJLx3SZY1KQwHA0Ye_G2km1NUdF4W-X7koECA',
        },
        data: data
    };

    let api_response = await axios(config)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log(error);
        });

    return api_response
}