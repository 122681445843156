import React, { Component } from 'react';

class BookAConsult extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="pt-5 pb-5" style={{ backgroundColor: '#1D1D1D' }}>
                <div className="mt-5 mb-5 container">
                    <h1 className="bc_title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">GET STARTED</h1>
                    <p className="bc_content mt-4" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">To get access to weekly tune-ups, assessments and training protocols that make you tougher, fitter and faster along with physical therapy that enables fluid movement, Myo Movement is your one-stop shop to live your best life. Get started now.</p>
                    <p className="bc_content mt-4"  data-aos-once={true} data-aos="fade-up" data-aos-duration="600">Ready to embark on your journey to a healthier, happier you? Join Myo Movement today and experience the difference that individualised care can make!</p>
                    <a href="/physio#physio-form" style={{ color: 'black', textDecoration: 'none', fontWeight: 'bold' }}><p className="bc_btn mx-auto text-center mt-4" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">BOOK NOW</p></a>
                </div>
            </div>
        )
    }
}

const styles = {

}

export default BookAConsult;