import React, { Component } from "react";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="pt-2 pb-2" style={{ backgroundColor: "transparent" }}>
        <div className="container mb-5 mt-5  centered-content">
          <h1
            className="about_title ml-2"
            data-aos-once={true}
            data-aos="fade-up"
            data-aos-duration="600"
          >
            MINASH GABRIEL
          </h1>
          <p
            className="about_sub_title ml-2"
            data-aos-once={true}
            data-aos="fade-up"
            data-aos-duration="600"
            style={{
              fontSize:'18px'
            }}
          >
            FOUNDER
          </p>
          <div className="row d-flex justify-content-center">
            {/* <div className="col-md-4"></div> */}
            <div className="col-md-4 mt-3">
              <img
                className="about_image"
                src="/images/team_1.png"
                data-aos-once={true}
                data-aos="fade-right"
                data-aos-delay={`100`}
                data-aos-duration="600"
                alt=""
              />
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 mt-3">
              <p
                className="bc_content"
                style={{
                  color: "#FFF",
                  lineHeight: 1.8,
                  textAlign: "left",
                }}
              >
                Ex-Ranji Trophy player turned Physiotherapist.
                <br />
                <br />
                When an injury forced him to retire from the game in his early
                twenties, Minash used his own rehabilitation as motivation to
                pursue a degree in Physical Therapy.
                <br />
                <br />
                He went on to get certified as an Osteopathic Manual Therapist
                from the European Union of Osteopaths (EUO), a Functional Range
                Conditioning Specialist (US), an Acupuncturist/Dry Needling
                Specialist, a Manual Therapist (IND), and certified
                Rehabilitation trainer (AUS).
                <br />
                <br />
                Minash believes that movement is medicine, and precision
                movement is magic.
                <br />
                <br />
                As a Physical Therapist, his take on exercise and human
                performance is founded on a deep understanding of kinesiology,
                biomechanics and years of immersing himself in various movement
                disciplines.
              </p>
              {/* <img
                className="about_image"
                src="/images/Tanya_Rao.png"
                data-aos-once={true}
                data-aos="fade-right"
                data-aos-delay={`100`}
                data-aos-duration="600"
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {};

export default Team;
