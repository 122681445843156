import React, { Component } from 'react';
import DropDown from '../Components/Common/DropDown';
import InputBox from '../Components/Common/InputBox';
import Header from '../Components/Sessions/Header';
import apicaller from '../utils/ApiCaller';
import Swal from 'sweetalert2'

import './MentorSessionStyles.css'

class MentorSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genderOptions: ["Male", "Female"],
            selectedGender: '',
            ageOptions: [1, 2, 3, 4],
            selectedAge: '',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            goals: '',
            description: '',
            isSubmittingForm: false
        };
    }

    handleOnSelectDropdown = (key, value) => {
        console.log(key, value);
        this.setState({
            [key]: value
        })
    }

    handleOnChangeValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    handleValidation = () => {
        let output = {};
        output.message = '';
        output.isValid = true;
        const rp = /^[0-9]{10}$/g;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.state.first_name || this.state.first_name == "") {
            output.message = "Please enter name.";
            output.isValid = false;
            return output;
        }
        if (!this.state.selectedGender || this.state.selectedGender == "") {
            output.message = "Please select gender.";
            output.isValid = false;
            return output;
        }
        if (!this.state.phone || this.state.phone == "" || !rp.test(this.state.phone)) {
            output.isValid = false;
            output.message = "Please enter a valid phone number.";
            return output;
        }
        if (!this.state.email || this.state.email == "" || !re.test(this.state.email)) {
            output.isValid = false;
            output.message = "Please enter a valid email.";
            return output;
        }
        if (!this.state.selectedAge || this.state.selectedAge == "") {
            output.message = "Please select age.";
            output.isValid = false;
            return output;
        }
        return output;
    }

    handleOnSubmit = () => {
        let validation = this.handleValidation();
        if (validation.isValid) {
            this.setState({
                isSubmittingForm: true
            })
            let data = {
                "fields": {
                    "Name": {
                        "value": this.state.first_name + " " + this.state.last_name
                    },
                    "Age": {
                        "value": parseInt(this.state.selectedAge)
                    },
                    "Gender": {
                        "value": this.state.selectedGender
                    },
                    "Email": {
                        "value": this.state.email
                    },
                    "Mobile_Number": {
                        "value": this.state.phone,
                        "code": "91"
                    },
                    "Enquiry_Type": {
                        "value": "Training"
                    },
                    "Training_Preference": {
                        "value": "Mentorship"
                    },
                    "Goals": {
                        "value": this.state.goals
                    },
                    "Scans": {
                        "value": ""
                    },
                    "Enquiry_Status": {
                        "value": "New"
                    }
                }
            }
            apicaller('post', data)
                .then(res => {
                    if (res.data.statusMessage === "SUCCESS") {
                        Swal.fire('Submitted!', `We received you'r request we will get back to you soon.`, 'success')
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        this.props.history.push({
                            pathname: '/',
                            state: { isReload: true }
                        })
                    }
                    this.setState({
                        isSubmittingForm: false
                    })
                })
                .catch(err => {
                    Swal.fire('Error!', `some internal issue.`, 'error')
                    console.log(err);
                    this.setState({
                        isSubmittingForm: false
                    })
                })
        }
        else {
            Swal.fire('Warning!', validation.message, 'warning')
        }
    }

    render() {
        return (
            <div>
                <Header
                    title={'MENTOR SESSION'}
                />
                <div style={{ backgroundColor: '#000000', overflowX: 'visible' }} id="session_form">
                    <div className="container pt-5 pb-5" style={{ overflowX: 'visible' }}>
                        <h1 className="physio_title" data-aos="fade-up" data-aos="fade-up" data-aos-duration="600">FILL THE FORM</h1>
                        <p className="physio_content" data-aos="fade-up" data-aos="fade-up" data-aos-duration="600">Go through our quick form to get in touch with us. Note* All data provided is NOT shared to any 3rd party companies. All information is safe, secure and within the MyoMovement ecosystem. </p>
                        <label className="physio_label mt-3" data-aos="fade-up" data-aos="fade-up" data-aos-duration="600">GENERAL DETAILS</label>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (!this.state.isSubmittingForm) {
                                this.handleOnSubmit()
                            }
                        }} data-aos="fade-up" data-aos-once={true} data-aos-duration="600">
                            <div style={{ overflowX: 'visible' }}>
                                <div className="row" style={{ overflowX: 'visible' }}>
                                    <div className="col-md-4 mt-3">
                                        <InputBox
                                            placeholder="FIRST NAME"
                                            keyName='first_name'
                                            handleOnChangeValue={this.handleOnChangeValue}
                                            value={this.state.first_name}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <InputBox
                                            placeholder="LAST NAME"
                                            keyName='last_name'
                                            handleOnChangeValue={this.handleOnChangeValue}
                                            value={this.state.last_name}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3" style={{ overflowX: 'visible' }}>
                                        <DropDown
                                            options={this.state.genderOptions}
                                            value={this.state.selectedGender}
                                            defaultValue="GENDER"
                                            keyName="selectedGender"
                                            handleOnSelectDropdown={this.handleOnSelectDropdown}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ overflowX: 'visible' }}>
                                    <div className="col-md-4 mt-3">
                                        <InputBox
                                            placeholder="MOBILE NUMBER"
                                            keyName='phone'
                                            handleOnChangeValue={this.handleOnChangeValue}
                                            value={this.state.phone}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <InputBox
                                            placeholder="EMAIL ID"
                                            keyName='email'
                                            handleOnChangeValue={this.handleOnChangeValue}
                                            value={this.state.email}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3" style={{ overflowX: 'visible' }}>
                                        <InputBox
                                            placeholder="Age"
                                            keyName='selectedAge'
                                            handleOnChangeValue={this.handleOnChangeValue}
                                            value={this.state.selectedAge}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <label className="physio_label mt-3">GOALS</label>
                                    <div className="mt-3">
                                        <textarea className="physio_textarea" placeholder="TYPE HERE" onChange={(e) => { this.handleOnChangeValue('goals', e.target.value) }}>{this.state.goals}</textarea>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <label className="physio_label mt-3">WHY YOU WANT TO DO THE MENTORSHIP SESSION?</label>
                                    <div className="mt-3">
                                        <textarea className="physio_textarea" placeholder="TYPE HERE" onChange={(e) => { this.handleOnChangeValue('description', e.target.value) }}>{this.state.description}</textarea>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4 mt-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                            <button className="physio_submit_button">
                                                <p className="mt-3 physio_submit_button_text">{this.state.isSubmittingForm ? "SUBMITTING...." : "SUBMIT"}</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {

}

export default MentorSessions;