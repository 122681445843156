import React, { Component } from 'react';

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matches: window.matchMedia("(min-width: 991.98px)").matches
        };
    }

    render() {
        return (
            <div style={this.props.fullContainerStyles}>
                <div className="container" style={this.props.containerStyles}>
                    <div className="row m-0">
                        <div className="col-lg-6" style={{ color: '#FFFFFF',paddingTop: '10%',paddingBottom: '10%' }}>
                            <p className="about_title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">{this.props.title}</p>
                            <p className="about_sub_title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">{this.props.subtitle}</p>
                            <p className="pagaragraph" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">{this.props.content}</p>
                            {this.props.content2 ?
                                (
                                    <>
                                        <p className="pagaragraph mt-3" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">{this.props.content2}</p>
                                    </>
                                )
                                : null
                            }
                            <div className="row mt-5 features_list">
                                <div className="d-flex align-item-start">
                                    <div className="d-flex flex-column align-items-center">
                                        {this.props.featureIcons && this.props.featureIcons.length > 0 ? this.props.featureIcons.map((item, index) => {
                                            console.log(index < this.props.featureIcons.length - 1, index, this.props.featureIcons.length - 1)
                                            return (< div className={index < this.props.featureIcons.length - 1 ? "mb-4" : ""} >
                                                <img src={item} data-aos-once={true} data-aos="fade-left" data-aos-duration="600" />
                                            </div>)
                                        }) : null}
                                    </div>
                                </div>
                                <div className="ml-4 d-flex flex-column justify-content-around">
                                    {this.props.featureLabels && this.props.featureLabels.length > 0 ? this.props.featureLabels.map((item, index) => (
                                        <div className={index < this.props.featureLabels.length - 1 ? "mb-4" : ""}>
                                            <p className="features_list_label" data-aos-once={true} data-aos="fade-left" data-aos-duration="600" style={{ margin: '0px' }}>{item}</p>
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent:'center' , alignSelf:'center' , height:'100%',paddingTop: '10%',paddingBottom: '10%' }} className={this.state.matches ? "col-lg-6 features_image" : "col-lg-6"}>
                            <img src={`${this.props.mainImage}`} className="about_image ml-1 rounded" data-aos-once={true} data-aos="fade-right" data-aos-offset="200" data-aos-duration="600" style={this.props.style} />
                        </div>
                    </div>
                </div>
            </div >
            // </div >
        )
    }
}

const styles = {

}

export default Features;