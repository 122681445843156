import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Swal from 'sweetalert2';
import apicaller from '../../utils/ApiCaller';
require("react-big-calendar/lib/css/react-big-calendar.css");

class AppointmentCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sundayOfWeek: moment().clone().startOf('week').day(0), // Get Sunday of the current week
            lastDayOfSlots: moment().clone().add(30, 'day'),
            events: [],
            isloading: true,
            bookedEvents: [],
            views: ['week', 'day'],
            defaultDate: null,
            dayInputs : []
        };
        this.localizer = momentLocalizer(moment);
        this.views = ['week', 'day'];
        this.today = new Date();
        this.minTime = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 10, 0, 0);
        this.maxTime = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 17, 0, 0);
        
    }

    // added changes

    componentDidMount() {
        // this.getBookedEvents();
        this.getDefaultSlots();
    };

    generateTimeSlots = async () => {
        const today = moment();
        const sundayOfWeek = moment(this.state.sundayOfWeek); // Adjust if your week starts on a different day
        const lastDayOfSlots = moment(this.state.lastDayOfSlots);
        const timeSlots = [];

        let defaultDate;


        let availabilityFlag = false;

        for (let currentDay = sundayOfWeek.clone(); currentDay.isBefore(lastDayOfSlots); currentDay.add(1, 'day')) {
            const dayOfWeek = currentDay.day();
            const dayInput = this.state.dayInputs.find(input => input[dayOfWeek]);

            if (!dayInput) {
                continue;
            }

            if (dayInput) {
                const inputArray = Object.values(dayInput[dayOfWeek]);


                inputArray.forEach(timeSlotInput => {
                    const startHour = timeSlotInput.start;
                    const endHour = timeSlotInput.end;

                    const isToday = currentDay.isSame(today, 'day');
                    const isAfterToday = currentDay.isAfter(today, 'day');

                    const startTime = currentDay.clone().hours(startHour.hours).minutes(startHour.minutes);
                    const endTime = currentDay.clone().hours(endHour.hours).minutes(endHour.minutes);


                    if (!availabilityFlag && (isToday && moment().isSameOrBefore(startTime) || isAfterToday)) {
                        availabilityFlag = true;
                    };

                    const timeSlot = {
                        id: startTime.valueOf(),
                        title: availabilityFlag ? 'Available' : 'Not available',
                        start: startTime.toDate(),
                        end: endTime.toDate(),
                        availability: availabilityFlag ? 'available' : 'not available',
                        selectable: availabilityFlag,
                    };

                    timeSlots.push(timeSlot);
                });
            }
        }

        const bookedEventsMap = new Map(this.state.bookedEvents.map(item => [item.id, item]));
        const events = timeSlots.map(item => {
            if (bookedEventsMap.has(item.id)) {
                return {
                    ...item,
                    availability: 'booked',
                    title: 'Booked',
                    selectable: false,
                    start: moment(item.start).toDate(),
                    end: moment(item.end).toDate(),
                };
            }

            if(availabilityFlag && !defaultDate && item.availability === 'available'){
                defaultDate = item.start;
            };

            return item;
        });

        this.setState({
            events: events,
            defaultDate : defaultDate,
            isloading: false,
        });
    };

    eventStyleGetter = (event, start, end, isSelected) => {
        // Customize the style based on availability or booking status
        if (event.availability === 'available') {
            return {
                style: {
                    backgroundColor: 'green', // Set your desired background color for available slots                      
                },
            };
        } else if (event.availability === 'booked') {
            return {
                style: {
                    backgroundColor: 'red', // Set your desired background color for booked slots
                },
            };
        } else if (event.availability === 'not available') {
            return {
                style: {
                    backgroundColor: '#666666', // Set your desired background color for not available slots
                },
            };
        }

        // Return null for other events to use the default styling
        return null;
    };

    handleNavigate = (data) => {
    };

    handleSelectEvent = (event) => {

        if (event.availability === 'available') {
            this.props.selectedDate(event);
        } else {
            // CREATE SWAL ALETR FOR NOT AVAILABLE
            Swal.fire('Error!', `Slot not available.`, 'error')
        }
    };

    getBookedEvents = () => {
        apicaller('physio-appointments/booked-appointments','GET', {})
        .then(res => {
            if (res.data.status === "success") {
                this.setState({
                    bookedEvents: res.data.bookedEvents,
                },async () => {
                    // this.getDefaultSlots();
                    this.generateTimeSlots();
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    };

    getDefaultSlots = () => {
        apicaller('physio-appointments/default-slots-structure','GET', {})
        .then(res => {
            if (res.data.status === "success") {
                console.log("response:",res.data);
                this.setState({
                    dayInputs: res.data.defaultSlots,
                },() => {
                    this.getBookedEvents();
                    // this.generateTimeSlots();
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    };


    render() {
        return (
            this.state.isloading ? <div>Loading...</div> : <div>
                <div className='mt-3'>
                <Calendar style={styles}
                    localizer={this.localizer}
                    events={this.state.events}
                    views={this.state.views}
                    defaultView={window.innerWidth <= 768 ? 'day' : 'week'}
                    eventPropGetter={this.eventStyleGetter}
                    onNavigate={this.handleNavigate}
                    onSelectEvent={this.handleSelectEvent}
                    startAccessor="start"
                    endAccessor="end"
                    min={this.minTime}
                    max={this.maxTime}
                    step={30}
                    timeslots={1}
                    defaultDate={this.state.defaultDate}
                />
                </div>
            </div>
        );
    }
};

const styles = {
    height: 700,
    width: 'auto',
    margin: 'auto',
    'margin-bottom': '50px',
    'color': 'white',
}

export default AppointmentCalendar;

