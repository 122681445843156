import React, { Component } from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import Header from '../Components/Contact/Header';

import './ContactStyles.css'

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isNavOpen: false,
        };
    }

    onToggleNavBar = () => {
        document.getElementById("myNav").style.width = this.state.isNavOpen ? "0%" : "100%";
        this.setState({
            isNavOpen: !this.state.isNavOpen
        })
    }

    render() {
        return (
            <div>
                <Navbar
                    onToggleNavBar={this.onToggleNavBar}
                    activeMenuItem={'CONTACT'}
                    isNavOpen={this.state.isNavOpen}
                />
                <div>
                    <div style={styles.headerBackground}>
                        <div style={{ height: '100vh' }}>
                            <div className="d-flex align-items-center justify-content-center" data-aos-once={true} data-aos="fade-up" data-aos-duration="600" style={{ height: '100%', width: '100%' }}>
                                <h1 className="header_title">CONTACT US</h1>
                            </div>
                            <div className="header_bottom" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">
                                <div className="container">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <img src="/images/contact_icon_1.svg" />
                                        </div>
                                        <div>
                                            <p className="heading ml-4">HYDERABAD OFFICE</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <a href="mailto:hello.myomovement@gmail.com">
                                                <div style={{ border: '2px solid white', height: '50px' }} className="pt-2 pb-2 mt-3 pl-3">
                                                    <div className="d-flex flex-row">
                                                        <div>
                                                            <img src="/images/message.svg" style={{ height: '30px', width: '30px' }} />
                                                        </div>
                                                        <div className="d-flex mx-auto mt-1">
                                                            <p style={{ color: 'white', margin: 0, fontFamily: 'din-2014, sans-serif', fontSize: '15px', fontWeight: 'bold' }}>HELLO.MYOMOVEMENT@GMAIL.COM</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="col-md-6">
                                            <a href="tel:+919849038954">
                                                <div className="pt-2 pb-2 mt-3 pl-3" style={{ border: '2px solid white', height: '50px' }}>
                                                    <div className="d-flex flex-row">
                                                        <div>
                                                            <img src="/images/call.svg" style={{ height: '25px', width: '30px' }} />
                                                        </div>
                                                        <div className="mx-auto mt-1">
                                                            <p style={{ color: 'white', margin: 0, fontFamily: 'din-2014, sans-serif', fontSize: '15px', fontWeight: 'bold' }}>+919849038954</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container pt-5 pb-5">
                            <div className="d-flex flex-row align-items-center">
                                <div>
                                    <img src="/images/pin.svg" data-aos-once={true} data-aos="fade-up" data-aos-duration="600" />
                                </div>
                                <div>
                                    <p className="heading ml-4" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">MAP</p>
                                </div>
                            </div>
                            <div className="pt-4 pb-5" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.6886344283685!2d78.40111891487683!3d17.426723988055095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91cb957cacb5%3A0x2d6ca60fe4493815!2sMyo%20Movement!5e0!3m2!1sen!2sin!4v1631088498949!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                        <Footer
                            isInstaVisible={true}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    headerBackground: { backgroundImage: "linear-gradient(180deg, #000000 0%, #424242 100%)", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed' },
}

export default Contact;