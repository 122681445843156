import React, { Component } from 'react';
class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={{ backgroundColor: '#292929', overflowX: 'hidden' }}>
                <div className="container pt-5 pb-5">
                    <h1 className="title pt-sm-0 pt-md-5 pt-lg-5" data-aos-once={true} data-aos="fade-up" data-aos-duration="600" data-aos-delay="200">{this.props.title}</h1>
                    <p className="sub-title" data-aos-once={true} data-aos="fade-up" data-aos-duration="600">{this.props.subtitle}</p>
                    <div className="row">
                        {this.props.list && this.props.list.length > 0 ? this.props.list.map((item, index) => (
                            <div className="col-6 col-md-3 pt-sm-5 pt-md-5 pt-lg-5 pb-sm-5 pb-md-5 pb-lg-5">
                                <div className="d-flex flex-column align-items-center" data-aos-once={true} data-aos="fade-left" data-aos-duration="600" data-aos-delay={`${index + 2}00`}>
                                <div className='d-none d-md-block d-lg-block'>
                                        <img src={item.icon} width={'85px'} height={'85px'} />
                                    </div>
                                    <div className='d-block d-md-none d-lg-none'>
                                        <img src={item.icon} width={'65px'} height={'65px'} />
                                    </div>
                                    <div className="mt-3">
                                        <p className="service_label">{item.label}</p>
                                    </div>
                                </div>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {

}

export default Services;